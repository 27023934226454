/* eslint-disable no-unused-vars */
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// Icons
import Selo from '../images/grau_alimenticio/icons/selo.svg'
import Icone1 from '../images/grau_alimenticio/icons/01.svg'
import Icone2 from '../images/grau_alimenticio/icons/02.svg'
import Icone3 from '../images/grau_alimenticio/icons/03.svg'
import Logo from '../images/grau_alimenticio/icons/nsf-logo.svg'
import handFlower from '../images/sustentabilidade/icons/Sustentabilidade_1.svg'

// CSS, SCSS
import './styles/teste.scss'
import { graphql } from 'gatsby'

// Assets

const GrauAlimenticio = ({ data }) => {
  const intl = useIntl()
  return (
    <>
      <div className="imagem-centralizada">
        <GatsbyImage
          image={data.Banner.childImageSharp.gatsbyImageData}
          className='Banner-Grau'
          alt="Imagem Produto Dentro de Categorias"
        />
        <img src={Selo} className='selo-grau' alt='Selo'></img>
        <div className="texto-centralizado w-lg-100 w-75">
          <h1 className='text-white'>{parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.banner_lwart.title' }))}</h1>
          <h5 className='font-size-12 text-white mt-3'>{parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.banner_lwart.text' }))}</h5>
        </div>
      </div>
      <section className='container-fluid p-0 position-relative'>
        <div className='espaco-entre-divs-grau'>
          <div className='position-absolute p-0 h-100 imagePosition d-none d-lg-block' style={{ width: '47%' }}>
            <GatsbyImage
              image={data.Pao.childImageSharp.gatsbyImageData}
              className='h-100 w-100'
              alt="Imagem Produto Dentro de Categorias"
            />
          </div>
          <div className='container'>
            <div className='d-lg-none d-block col-12'>
              <GatsbyImage
                image={data.Pao.childImageSharp.gatsbyImageData}
                className='h-100 w-100'
                alt="Imagem Produto Dentro de Categorias"
              />
            </div>
            <div className='col-lg-6 col-12'>
              <p className='pt-3' style={{ fontSize: '17px' }}>{parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.section_text.p1' }))}</p>
              <p className='pb-3' style={{ fontSize: '17px' }}>{parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.section_text.p2' }))}</p>
            </div>
          </div>
        </div>
      </section>
      <div className='d-lg-block d-none position-relative espaco-entre-divs-grau' style={{ marginTop: '6em' }}>
        <div className='container' style={{ marginBottom: '24px' }}>
          <div className='d-flex posicao-teste-grau'>
            <div className='d-flex justify-content-between'>
              <div className='card-1-grau'>
                <img src={Icone1} className='' alt={parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.card1.alt' }))}></img>
                <p className=''>{parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.card1.p1' }))}</p>
              </div>
              <div className='card-1-grau' style={{ background: 'linear-gradient(to bottom, #1D4F88 50%, #7795B7 50%)' }}>
                <img src={Icone2} alt={parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.card2.alt' }))}></img>
                <p className=''>{parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.card2.p1' }))}<a href='https://info.nsf.org/usda/psnclistings.asp' target='_blank' rel="noreferrer" className='white-book'>{parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.card2.link' }))}</a>
                </p>
              </div>
              <div className='card-1-grau' style={{ background: 'linear-gradient(to bottom, #93C248 50%, #B3D47E 50%)' }}>
                <img src={Icone3} alt={parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.card3.alt' }))}></img>
                <p className=''>{parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.card3.p1' }))}</p>
              </div>
            </div>
          </div>
          <div className='d-flex posicao-teste-grau' style={{ marginTop: '5em' }}>
            <div className='d-block'>
              <div className='d-flex'>
                <div style={{ width: '41%' }}>
                  <p style={{ lineHeight: '1.4em', fontSize: '17px' }} className='text-white'>{parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.section_cards_text.p1' }))}</p>
                </div>
                <div style={{ marginLeft: '5.4em' }}>
                  <img src={Logo} style={{ height: '115px', width: '100%', float: 'right' }} alt="Logo"></img>
                </div>
              </div>
              <p className='mt-5 text-white'>{parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.section_cards_text.small' }))}</p>
            </div>
          </div>
        </div>
        <div className=''>
          <GatsbyImage
            image={data.Azul.childImageSharp.gatsbyImageData}
            className='w-100 position-absolute'
            style={{ top: '9em', zIndex: -1, height: '80%' }}
            alt="Imagem Produto Dentro de Categorias"
          />
        </div>
      </div>
      <div className='d-lg-none d-block'>
        <div className='position-relative'>
          <div className='container'>
            <div className='col-12'>
              <div className='d-flex justify-content-center'>
                <div className='card-1-grau'>
                  <img src={Icone1} className='' alt={parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.card1.alt' }))}></img>
                  <p className=''>{parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.card1.p1' }))}</p>
                </div>
              </div>
            </div>
            <div className='col-12 mt-4'>
              <div className='d-flex justify-content-center'>
                <div className='card-1-grau' style={{ background: 'linear-gradient(to bottom, #1D4F88 50%, #7795B7 50%)' }}>
                  <img src={Icone2} alt={parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.card2.alt' }))}></img>
                  <p className=''>{parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.card2.p1' }))}<a href='https://info.nsf.org/usda/psnclistings.asp' target='_blank' rel="noreferrer" className='white-book'>{parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.card2.link' }))}</a>
                  </p>
                </div>
              </div>
            </div>
            <div className='col-12 mt-4'>
              <div className='d-flex justify-content-center'>
                <div className='card-1-grau' style={{ background: 'linear-gradient(to bottom, #93C248 50%, #B3D47E 50%)' }}>
                  <img src={Icone3} alt={parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.card3.alt' }))}></img>
                  <p className=''>{parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.card3.p1' }))}</p>
                </div>
              </div>
            </div>
            <div className='col-12 mt-5' style={{ marginBottom: '2.6em' }}>
              <p style={{ lineHeight: '1.4em' }} className='text-white'><strong>Os produtos dessa linha são certificados
              pela National Sanitation Foundation (NSF),
              empresa americana de testes reconhecida
              internacionalmente pela inspeção e
              certificação de produtos. </strong></p>
              <p className='text-white'>*Verificar viscosidades certificadas H2</p>
              <div className='d-flex justify-content-center'>
                <img src={Logo} className='w-100' alt="Logo"></img>
              </div>
            </div>
          </div>
          <div className=''>
            <GatsbyImage
              image={data.Azul.childImageSharp.gatsbyImageData}
              className='w-100 position-absolute'
              style={{ top: '56.8em', zIndex: -1, height: '18.5em' }}
              alt="Imagem Produto Dentro de Categorias"
            />
          </div>
        </div>
      </div>
      <div className="container p-lg-5 pt-5 pb-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-9">
            <div className="container">
              <div className="row">
                <div className="col-lg-2 col-4 mb-lg-0 mb-2">
                  <div className="d-flex justify-content-lg-end">
                    <img src={handFlower} className="rounded-circle bg-lwart-green3 handflorLWVOLT" alt="Icone" />
                  </div>
                </div>
                <div className="col-lg-10">
                  <p className="font-Spinnaker text-lwart-green3 mb-lg-0 mb-3">{parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.sustentabilidade.p1' }))}</p>
                  <h3 className="font-Spinnaker text-lwart-blue pb-3">{parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.sustentabilidade.p2' }))}</h3>
                  <p>{parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.sustentabilidade.p3' }))}</p>
                  {parse(intl.formatMessage({ id: 'paginas.grau_alimenticio.sustentabilidade.p4' }))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='d-lg-block d-none container' style={{ marginTop: '100px', marginBottom: '70px' }}>
        <div className='posicao-grau'>
          <div className='d-flex'>
            <div className='position-relative'>
              <img src={Icon} className='position-absolute' style={{ top: '0px' }} alt='Icon'></img>
            </div>
            <div style={{ marginLeft: '6.4em' }}>
              <h5 style={{ color: '#93C248' }}>SUSTENTABILIDADE</h5>
              <h3 style={{ color: '#1D4F88' }} className='mt-3'>Seja parte deste ciclo!</h3>
              <p className='mt-4'>Quando você escolhe a <strong>Lwart Soluções Ambientais</strong> para a coleta do óleo usado ou<br></br>
              compra produtos provenientes do rerrefino, torna-se parte de um círculo virtuoso que<br></br>
              contribui para o desenvolvimento sustentável do país e a preservação da natureza. </p>
              <p className='mt-4'><strong>Venha fazer parte!</strong></p>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className='d-lg-none d-block'>
        <div className='container'>
          <div className='col-12'>
            <div className='d-flex justify-content-center'>
              <img src={Icon} alt='Icon'></img>
            </div>
          </div>
          <div className='col-12' style={{ marginBottom: '2em' }}>
            <h5 style={{ color: '#93C248' }} className='text-center'>SUSTENTABILIDADE</h5>
            <h3 style={{ color: '#1D4F88' }} className='text-center'>Seja parte deste ciclo!</h3>
            <p className='mt-2'>Quando você escolhe a <strong>Lwart Soluções Ambientais</strong> para a coleta do óleo usado ou<br></br>
              compra produtos provenientes do rerrefino, torna-se parte de um círculo virtuoso que<br></br>
              contribui para o desenvolvimento sustentável do país e a preservação da natureza. </p>
            <p><strong>Venha fazer parte!</strong></p>
          </div>
        </div>
      </div> */}
    </>
  )
}
export default GrauAlimenticio

export const query = graphql`{
  Banner: file(
    relativePath: {eq: "grau_alimenticio/banner.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  Pao: file(
    relativePath: {eq: "grau_alimenticio/pao.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  Logo: file(
    relativePath: {eq: "grau_alimenticio/nsf-logo.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
  Azul: file(
    relativePath: {eq: "grau_alimenticio/foto-fundo-azul.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100)
    }
  }
}
`
